<template>
  <DataTable
    :table-key="'DataTableSimple_' + tableKey"
    :row-key="rowKey"
    :title="title"
    :columns="columns"
    :rows="rows"
    :loading="loading"
    :pagination="pagination"
    :rows-per-page-options="[]"
    :downloadable="false"
    :refreshable="false"
    :customizable="false"
    :filterable="false"
    :searchable="false"
    :disable-state="true"
    :hide-top="true"
    :flat="flat"
    :dense="true"
    :height="height"
    class="datatable__simple --scroll-shadows"
    :fullscreen="false"
  />
</template>

<script>
import DataTable from "@/components/UI/DataTable";

export default {
  name: "DataTableSimple",
  components: { DataTable },
  props: {
    tableKey: {
      type: String,
      required: true
    },
    rowKey: {
      type: String,
      default: "id"
    },
    title: {
      type: String,
      default: ""
    },
    columns: {
      type: Array,
      required: true
    },
    rows: {
      type: Array,
      required: false
    },
    pagination: {
      type: Object,
      default: () => ({
        sortBy: "",
        descending: false,
        page: 1,
        rowsPerPage: 10
      })
    },
    loading: {
      type: Boolean,
      default: false
    },
    spinnerLoader: {
      type: Boolean,
      default: false
    },
    flat: {
      type: Boolean,
      default: true
    },
    height: {
      type: String,
      default: ""
    }
  },
  data() {
    return {};
  },
  methods: {}
};
</script>

<style scoped lang="scss">
.datatable__simple {
  border-bottom: 1px solid color(border);
}
</style>
